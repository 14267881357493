import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { CommonService } from 'src/app/services/common.service';
import { ConfigService } from 'src/app/services/config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'calsoft-footer9',
  templateUrl: './footer9.component.html',
  styleUrls: ['./footer9.component.scss']
})
export class Footer9Component implements OnInit {
  categoryArray: any = [];
  flexValue: string = 'calc(50% - 2px)';
  @Input() data: any;
  showFooterIcon: number;
  footerIconData: any[];
  footerBrandImg: any;
  footerImageEnable: any=0;
  url: string;
  footerBackgroundImg: any;

  constructor(
    private categoryService: CategoryService,    
    private configService:ConfigService,
    public router: Router, 
    private commonService: CommonService) {
      this.url = this.router.url;
  }


  footerLogoSrc: any;
  display: boolean=false;

  public serverPath = environment.commonImageApi + "footerImage/";

  public serverPath1 = environment.commonImageApi + "footerBrands/";

  public serverPath2 = environment.commonImageApi + "footerBackground/";

  currentYear: number = new Date().getFullYear();

  ngOnInit(): void {

    if(window.innerWidth>959){
      this.display=true;
    }

    this.getCategories();


    if (this.data.footerLogoSrc != undefined) {
      this.footerLogoSrc = this.data.footerLogoSrc;
    }

    if (this.data.footerBrandImg != undefined) {
      this.footerBrandImg = this.data.footerBrandImg;
    }

    if (this.data.footerBackgroundImg != undefined) {
      this.footerBackgroundImg = this.data.footerBackgroundImg;
    }

    if(this.data.footerImageEnable!=undefined){
      this.footerImageEnable=this.data.footerImageEnable;
    }

    if(this.data.showFooterIcon==1){

      this.showFooterIcon=1;
      this.getFooterIcon();
    }


  }

  imageLoaded: boolean = true;

  onImageError() {
    this.imageLoaded = false;
  }

  getFooterIcon() {
    this.configService.getFooterData()
      .subscribe(
        data => {


          if (data['datalist'].length == 0) {
            this.showFooterIcon = 0;
            this.footerIconData = [];
          } else {
            this.footerIconData = data['datalist'];
          }

          if (this.data.footerLogoSrc != undefined) {
            this.footerLogoSrc = this.data.footerLogoSrc;
          }

          if (this.data.footerBrandImg != undefined) {
            this.footerBrandImg = this.data.footerBrandImg;
          }


        }, error => {

        }
      );
  }

  getCategories() {
    this.categoryService.categoryListSubMenu().subscribe(
      result => {
        this.categoryArray = result['data'];

      },
      error => {
      }
    )
  }

  isContentVisible: boolean = false;
  isContentVisible1: boolean = false;


  toggleContent() {
    this.isContentVisible = !this.isContentVisible;
  }

  toggleContent1() {
    this.isContentVisible1 = !this.isContentVisible1;
  }

  goToCategory(menu) {

    this.commonService.selectedCategory(menu.categoryName, menu.categoryId, menu);

  }

}
