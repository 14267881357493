import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagComponent } from './flag/flag.component';
import { HeaderFiveComponent } from './header/header-five/header-five.component';
import { HeaderfourComponent } from './header/headerfour/headerfour.component';
import { CalsoftHeaderComponent } from './header/HeaderOne/HeaderOne.component';
import { HeaderThreeComponent } from './header/HeaderThree/HeaderThree.component';
import { HeaderTwoComponent } from './header/HeaderTwo/header-two.component';
import { LanguageComponent } from './language/language.component';
import { LogoComponent } from './logo/logo.component';
import { MenuComponent } from './menu/menu/menu.component';
import { NestedChildComponent } from './menu/menu/nested-child/nested-child.component';
import { SideBarMenuComponent } from './menu/menu/side-bar-menu/side-bar-menu.component';
import { SearchComponent } from './search/search.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MobileHeaderComponent } from './header/mobile-header/mobile-header.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { MatListModule } from '@angular/material/list';
import { HeaderUserProfileDropdownComponent } from './header/HeaderUserProfileDropdown/HeaderUserProfileDropdown.component';
import { GoogleAnalyticsDirectiveModule } from 'src/app/shared/google-analytics-directive/google-analytics-directive.module';
import { CallComponent } from './header/call/call.component';
import { Menu2Component } from './menu/menu/menu2/menu2.component';
import { HeaderSixComponent } from './header/header-six/header-six.component';
import { HeaderSevenComponent } from './header/header-seven/header-seven.component';
import { HeaderEightComponent } from './header/header-eight/header-eight.component';
import { HeadernineComponent } from './header/headernine/headernine.component';
import { MobileHeaderFixedComponent } from './header/mobile-header-fixed/mobile-header-fixed.component';
import { CartIconComponent } from './header/cart-icon/cart-icon.component';
import { MenuIterationComponent } from './menu/menu/menu-iteration/menu-iteration.component';
import { FooterComponent } from './footer/footer.component';
import { Footer1Component } from './footer1/footer1.component';
import { Footer3Component } from './footer3/footer3.component';
import { Footer2Component } from './footer2/footer2.component';
import { SubscriberButtonComponent } from './subscriber-button/subscriber-button.component';
import { Footer4Component } from './footer4/footer4.component';
import { Header10Component } from './header/header10/header10.component';
import { MobileHeaderFixedWhiteComponent } from './header/mobile-header-fixed-white/mobile-header-fixed-white.component';
import { Header13Component } from './header/header13/header13.component';
import { Header14Component } from './header/header14/header14.component';
import { Header15Component } from './header/header15/header15.component';
import { PincodeComponent } from '../pincode/pincode.component';
import { PincodeModule } from '../pincode/pincode.module';
import { Search2Component } from './search/search2/search2.component';
import { Header11Component } from './header/header11/header11.component';
import { LeftMenuComponent } from './menu/left-menu/left-menu.component';
import { LeftMenuSubComponent } from './menu/left-menu-sub/left-menu-sub.component';
import { Menu3Component } from './menu/menu3/menu3.component';
import { Header12Component } from './header/header12/header12.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { Header16Component } from './header/header16/header16.component';
import { Header18Component } from './header/header18/header18.component';
import { Header17Component } from './header/header17/header17.component';
import { Header19Component } from './header/header19/header19.component';
import { PipesModule } from "../pipes/pipes.module";
import { Header20Component } from './header/header20/header20.component';
import { Header21Component } from './header/header21/header21.component';
import { MenuIteration2Component } from './menu/menu/menu-iteration2/menu-iteration2.component';
import { MobileHeaderNewComponent } from './header/mobile-header-new/mobile-header-new.component';
import { Footer5Component } from './footer5/footer5.component';
import { Header22Component } from './header/header22/header22.component';
import { Header23Component } from './header/header23/header23.component';
import { Header24Component } from './header/header24/header24.component';
import { Header25Component } from './header/header25/header25.component';
import { NewsletterAdsComponent } from './newsletter-ads/newsletter-ads.component';
import { Header26Component } from './header/header26/header26.component';
import { HoveremenuComponent } from './menu/hoveremenu/hoveremenu.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { FooterHeaderComponent } from './footer-header/footer-header.component';
import { Header27Component } from './header/header27/header27.component';
import { Search3Component } from './search/search3/search3.component';
import { Header28Component } from './header/header28/header28.component';
import { Header29Component } from './header/header29/header29.component';
import { Header30Component } from './header/header30/header30.component';
import { Header31Component } from './header/header31/header31.component';
import { Footer6Component } from './footer6/footer6.component';
import { Footer7Component } from './footer7/footer7.component';
import { Header32Component } from './header/header32/header32.component';
import { DynamicFormPopupComponent } from './dynamic-form-popup/dynamic-form-popup.component';
import { DynamicComponentModule } from 'src/app/shared/component/dynamic-component.module';
import { ButtonCategoryComponent } from './menu/button-category/button-category.component';
import { ButtonDialogComponent } from './menu/button-dialog/button-dialog.component';
import { MobileHeader3Component } from './header/mobile-header3/mobile-header3.component';
import { Header33Component } from './header/header33/header33.component';
import { Header34Component } from './header/header34/header34.component';
import { Header35Component } from './header/header35/header35.component';
import { Footer8Component } from './footer8/footer8.component';
import { SubscriberButton2Component } from './subscriber-button2/subscriber-button2.component';
import { SidemenuChildComponent } from './menu/menu/side-bar-menu/sidemenu-child/sidemenu-child.component';
import { MobileHeader4Component } from './header/mobile-header4/mobile-header4.component';
import { SidebarMenuNewComponent } from './menu/menu/side-bar-menu/sidebar-menu-new/sidebar-menu-new.component';
import { MobileHeader5Component } from './header/mobile-header5/mobile-header5.component';
import { BookPopupComponent } from './header/book-popup/book-popup.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import { Footer9Component } from './footer9/footer9.component';
import { Header36Component } from './header/header36/header36.component';
import { Header37Component } from './header/header37/header37.component';

@NgModule({
    declarations: [
        CalsoftHeaderComponent,
        HeaderTwoComponent,
        HeaderThreeComponent,
        HeaderfourComponent,
        HeaderFiveComponent,
        MenuComponent,
        NestedChildComponent,
        SideBarMenuComponent,
        SearchComponent,
        LogoComponent,
        LanguageComponent,
        FlagComponent,
        MobileHeaderComponent,
        SubscriberComponent,
        HeaderUserProfileDropdownComponent,
        CallComponent,
        Menu2Component,
        HeaderSixComponent,
        HeaderSevenComponent,
        HeaderEightComponent,
        HeadernineComponent,
        MobileHeaderFixedComponent,
        CartIconComponent,
        MenuIterationComponent,
        FooterComponent,
        Footer1Component,
        Footer3Component,
        Footer2Component,
        SubscriberButtonComponent,
        Footer4Component,
        Header10Component,
        MobileHeaderFixedWhiteComponent,
        Header13Component,
        Header14Component,
        Header15Component,
        Search2Component,
        Header11Component,
        LeftMenuComponent,
        LeftMenuSubComponent,
        Menu3Component,
        Header12Component,
        Header16Component,
        Header18Component,
        Header17Component,
        Header19Component,
        Header20Component,
        Header21Component,
        MenuIteration2Component,
        MobileHeaderNewComponent,
        Footer5Component,
        Header22Component,
        Header23Component,
        Header24Component,
        Header25Component,
        NewsletterAdsComponent,
        Header26Component,
        HoveremenuComponent,
        FooterHeaderComponent,
        Header27Component,
        Search3Component,
        Header28Component,
        Header29Component,
        Header30Component,
        Header31Component,
        Footer6Component,
        Footer7Component,
        Header32Component,
        DynamicFormPopupComponent,
        ButtonCategoryComponent,
        ButtonDialogComponent,
        MobileHeader3Component,
        Header33Component,
        Header34Component,
        Header35Component,
        Footer8Component,
        SubscriberButton2Component,
        SidemenuChildComponent,
        MobileHeader4Component,
        SidebarMenuNewComponent,
        MobileHeader5Component,
        BookPopupComponent,
        Footer9Component,
        Header36Component,
        Header37Component,

        
    ],
    exports: [
        RouterModule,
        CalsoftHeaderComponent,
        HeaderTwoComponent,
        HeaderThreeComponent,
        HeaderfourComponent,
        HeaderFiveComponent,
        MenuComponent,
        NestedChildComponent,
        SideBarMenuComponent,
        LogoComponent,
        FlexLayoutModule,
        CallComponent,
        HeaderSixComponent,
        HeaderSevenComponent,
        HeaderEightComponent,
        HeadernineComponent,
        MobileHeaderFixedComponent,
        MenuIterationComponent,
        FooterComponent,
        Footer1Component,
        Footer2Component,
        Footer3Component,
        Footer4Component,
        SubscriberButtonComponent,
        Header10Component,
        MobileHeaderFixedWhiteComponent,
        Header13Component,
        Header14Component,
        Header15Component,
        Header11Component,
        LeftMenuComponent,
        LeftMenuSubComponent,
        Header12Component,
        Header16Component,
        Header18Component,
        Header17Component,
        Header19Component,
        Header20Component,
        Header21Component,
        Header22Component,
        MobileHeaderNewComponent,
        Footer5Component,
        Header23Component,
        Header24Component,
        Header25Component,
        NewsletterAdsComponent,
        Header26Component,
        FooterHeaderComponent,
        Header27Component,
        Search3Component,
        Header28Component,
        Header29Component,
        Header30Component,
        Header31Component,
        Footer6Component,
        Footer7Component,
        Header32Component,
        DynamicFormPopupComponent,
        MobileHeader3Component,
        Header33Component,
        Header34Component,
        Header35Component,
        Footer8Component,
        SubscriberButton2Component,
        MobileHeader4Component,
        MobileHeader5Component,
        Footer9Component,
        Header36Component,
        Header37Component
    ],
    imports: [
        RouterModule,
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        FlexLayoutModule,
        MatDividerModule,
        RouterModule,
        MatAutocompleteModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatListModule,
        GoogleAnalyticsDirectiveModule,
        PincodeModule,
        MatPaginatorModule,
        MatDialogModule,
        MatDividerModule,
        MatIconModule,
        MatAutocompleteModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        FormsModule,
        MatToolbarModule,
        MatTableModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        PipesModule,
        LazyLoadImageModule,
        DynamicComponentModule,
        MatExpansionModule,
        MatFormFieldModule
    ]
})
export class ComponentModule { }
